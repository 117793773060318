import React from 'react'
import Layout from 'components/layout'
import Article from 'components/Article/'
import ImageParallax from 'components/ImageParallax/'
import { enumTheme } from 'config'
import { getCurrentLang } from 'utils'
import { ParallaxProvider } from 'react-scroll-parallax'
import { getPageTitle } from '../slugs'

export default props => {
  const {
    data: {
      photosArticlePiscine,
      banniereImageFooter,
      descriptionArticlePiscine,
    },
    slugs,
    lang
  } = props

  let height = 800
  if (typeof window !== `undefined`) {
    height = window.innerHeight
  }

  return (
    <ParallaxProvider>
      <Layout
        slugs={slugs}
        lang={lang}
        pageTitle={getPageTitle('piscine', lang)}
        headerScroll={0}
        footerTheme={enumTheme.WHITE}
        socialMediaIsDark={false}
        socialMediaScroll={0}
        socialMediaOffset={0}
        baseUrl={getCurrentLang(props.location.pathname)}
        currentPage={ props.currentPage }
      >
        <Article
          theme={enumTheme.WHITE}
          imgFull={photosArticlePiscine[0]}
          img1={photosArticlePiscine[1]}
          img2={photosArticlePiscine[2]}
          img3={photosArticlePiscine[3]}
          text={descriptionArticlePiscine}
          height={height - height / 3}
          baseUrl={getCurrentLang(props.location.pathname)}
        />
        <ImageParallax
          img={banniereImageFooter}
        />
      </Layout>
    </ParallaxProvider>
  )
}
