import React from 'react'
import Piscine from 'templates/piscine'
import { graphql } from 'gatsby'
import { getFieldLang } from 'utils'

export default props => {
  const {
    data: {
      allDatoCmsPiscine
    },
    pageContext: {
      lang,
      slugs
    }
  } = props

  const data = allDatoCmsPiscine && allDatoCmsPiscine.edges[0].node

  return (
    <div>
      {data &&
        <Piscine
          data={{
            ...data,
            descriptionArticlePiscine: data[`descriptionArticlePiscine${ lang }`],
          }}
          location={{ pathname: '/' }}
          currentPage={ props.location.pathname }
          lang={lang}
          slugs={slugs}
        />
      }
    </div>
  )
}

export const query = graphql`
  query PiscineQuery($website: String!) {
    allDatoCmsPiscine(filter: {website: {eq: $website}}) {
      edges {
        node {
          photosArticlePiscine {
            fluid(maxWidth: 500, imgixParams: {fm: "jpg", auto: "compress"}) {
              ...GatsbyDatoCmsFluid
            }
          }
          banniereImageFooter {
            fluid(maxWidth: 1920, imgixParams: {fm: "jpg", auto: "compress"}) {
              ...GatsbyDatoCmsFluid
            }
          }
          descriptionArticlePiscineFr
          descriptionArticlePiscineEn
          descriptionArticlePiscineIt
          descriptionArticlePiscineDe
        }
      }
    }
  }
`
